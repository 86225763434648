import React from "react";
import { createRoot } from 'react-dom/client';
import './sass/custom.scss';
import './sass/styles.scss';
import ReactGA from 'react-ga';
import Main from './Main'

ReactGA.initialize('UA-180628590-1');
ReactGA.pageview(window.location.pathname + window.location.search);
 
// ReactDOM.render(
//   <Main/>, 
//   document.getElementById("root")
// );

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<Main/>);