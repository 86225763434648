import React, { Component } from "react";
 
class Register extends Component {
  render() {
    return (
      <div>
        <h1 className="mt-3">Register</h1>

        <p>*Due to the changes in COVID-19 Restrictions in BC we will be doing our best to keep you all up to date if dates change or if any events get cancelled.  If you are registered you will recieve direct emails with any changes as they come out.</p>

        <p></p>
        <table className="table mb-5">
          <tbody>
            <tr>
              <th className="" scope="row">Ice X</th>
              <td className="text-right">Jan 11 / 12</td>
              <td className="text-right">Jan 25 / 26</td>
              <td className="text-right">Feb 22 / 23</td>
            </tr>
            <tr>
              <th className="" scope="row">Ice Attack</th>
              <td className="text-right">Feb 8 / 9</td>
              <td className="text-right">&nbsp;</td>
              <td className="text-right">&nbsp;</td>
            </tr>
          </tbody>
        </table>

        <a className="btn btn-info btn-lg mb-5" href="https://www.motorsportreg.com/orgs/cars-on-ice-wcirabc" target="_blank" rel="noopener noreferrer">Register Now on MotorsportReg</a>


        <p>Lunch can be purchased in advance on Motorsportreg for $40 for both days</p>
        <p>
          <small><em>Dates are subject to change due to ice conditions</em></small><br/>
          <small><em>No refunds (If Cars on Ice cancels an event, refunds will be given)</em></small>
        </p>


        <a className="btn btn-info btn-lg mr-3 mb-5" href="https://facebook.com/carsonice.ca" target="_blank" rel="noopener noreferrer">Join Us on Facebook</a>

        <h2>Volunteers</h2>
        <p>Cars on Ice is always seeking new volunteers. Without the volunteers, the races cannot continue. Volunteering is a great way to get to know the sport, meet new people and have fun.</p>
        <p>Many positions are available (but not limited to):</p>
        <ul>
          <li>Pre-grid Marshall</li>
          <li>Turn Worker / Flagger</li>
          <li>Pace Car</li>
          <li>Safety</li>
          <li>Timing and Scoring</li>
          <li>Start/Finish</li>
          <li>Tech Inspector</li>
          <li>Pit Marshall</li>
          <li>Plow Truck Driver</li>
          <li>Worker Director</li>
        </ul>

        <p>To become a volunteer, please contact us at <a href="mailto:info@carsonice.ca?Subject=I Want To Volunteer!">info@carsonice.ca</a> or register as a volunteer on <a href="https://www.motorsportreg.com/orgs/cars-on-ice-wcirabc" target="_blank" rel="noopener noreferrer">motorsportreg.com</a></p>
      </div>
    );
  }
}
 
export default Register;